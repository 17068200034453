import { ReactNode } from 'react';
import ZendeskWidget from '../ZendeskWidget';
import MessengersContext from './MessengersContext';
import useMessengersContextLogic from './useMessengersContextLogic';

export interface Props {
  children: ReactNode;
}

export default function MessengersContextProvider(props: Props) {
  const { children } = props;

  const ctxValue = useMessengersContextLogic();
  const { zendeskWidgetProps } = ctxValue;

  return (
    <MessengersContext.Provider value={ctxValue}>
      {children}

      {zendeskWidgetProps && (
        <ZendeskWidget {...zendeskWidgetProps } />
      )}
    </MessengersContext.Provider>
  );
}
