import Image from 'next/image';
import useLocalize from "src/services/localize/useLocalize";
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { MessengerLauncherButtonProps } from '../Messengers.types';

const MessengerLauncherButton = ({ onClick, dataMarker = 'Zendesk Button' }: MessengerLauncherButtonProps) => {
  const localize = useLocalize();

  return (
    <StylishBox
      element='span'
      elementProps={{ onClick }}
      dataTestId='zendesk_button'
      dataMarker={dataMarker}
      sbs={{ cursor: 'pointer' }}
    >
      <Image
        src='https://img4.zakaz.ua/about_contacts/zendesk3.png'
        alt={localize('chat_online')}
        width={40}
        height={40}
      />
    </StylishBox>
  );
};

export default MessengerLauncherButton;
