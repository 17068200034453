import { Fragment } from "react";
import MessengerColumnLinks from './components/MessengerColumnLinks';
import MessengersList from './components/MessengersList';
import useMessengersContext from './context/useMessengersContext';
import { MessengersProps } from "./Messengers.types";

const Messengers = (props: MessengersProps) => {
  const { variant } = props;

  const { socials, listOfMessengersProps, zendeskButtonProps, socialsAreReady } = useMessengersContext();

  return (
    <Fragment>
      {socialsAreReady && (
        <Fragment>
          {variant === 'column' && (
            <MessengerColumnLinks
              socials={socials}
              zendeskButtonProps={zendeskButtonProps}
              listOfMessengersProps={listOfMessengersProps}
            />
          )}

          {variant === 'line' && (
            <MessengersList
              socials={socials}
              zendeskButtonProps={zendeskButtonProps}
              onClick={listOfMessengersProps.close}
              variant='line'
            />
          )}
        </Fragment>
      )}


    </Fragment>
  );
};

export default Messengers;
