import { useEffect } from "react";
import StylishBox from "src/ui-kit/StylishBox/StylishBox";
import { useTheme } from "src/ui-kit/theme";
import { SocialMessenger, ZendeskButtonProps } from "../Messengers.types";
import { ListOfMessengersProps } from "../context/MessengersContext";
import MessengerLauncherButton from "./MessengerLauncherButton";
import MessengersList from "./MessengersList";

interface MessengerColumnLinksProps {
  socials: SocialMessenger[];
  zendeskButtonProps?: ZendeskButtonProps;
  listOfMessengersProps: ListOfMessengersProps;
}

const wrapperClassName = 'MessengerColumnLinks';

const MessengerColumnLinks = (props: MessengerColumnLinksProps) => {
  const {
    socials,
    zendeskButtonProps,
    listOfMessengersProps,
  } = props;
  const theme = useTheme();

  // enable/disable document click handler to close messenger list
  useEffect(() => {
    if (listOfMessengersProps.isOpen) {
      const handleClickDocument = (event: MouseEvent) => {
        const target = (event.target as Element);
        const targetIsChildOfMessengers = !!target?.closest(
          `.${wrapperClassName}`,
        );

        if (!targetIsChildOfMessengers) {
          listOfMessengersProps.close();
        }
      };

      document.addEventListener('click', handleClickDocument, true);

      return () => document.removeEventListener('click', handleClickDocument, true);
    }
  }, [listOfMessengersProps]);

  return (
    <StylishBox
      className={wrapperClassName}
      sbs={{
        position: 'fixed',
        bottom: '32px',
        right: '32px',
        zIndex: theme.base.zIndex.zendeskButton,
      }}
    >
      {listOfMessengersProps.isOpen ? (
        <MessengersList
          zendeskButtonProps={zendeskButtonProps}
          socials={socials}
          variant='column'
          closeButtonProps={{
            onClick: listOfMessengersProps.close,
          }}
        />
      ) : (
        <MessengerLauncherButton dataMarker='LauncherButton' onClick={listOfMessengersProps.open} />
      )}
    </StylishBox>
  );
};

export default MessengerColumnLinks;
