import { useContext } from "react";
import AuthServiceContext from "./AuthServiceContext";

const useAuthService = () => {
  const context = useContext(AuthServiceContext);

  if (!context) {
    throw new Error('AuthServiceContext is used without provider');
  }

  return context;
};

export default useAuthService;
