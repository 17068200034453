import Image from 'next/image';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { StylishBoxStyles } from 'src/ui-kit/StylishBox/types';
import { MessengerCloseButtonProps, SocialMessenger, ZendeskButtonProps } from '../Messengers.types';
import MessengerCloseButton from './MessengerCloseButton';
import MessengerLauncherButton from './MessengerLauncherButton';

export type MessengersListVariant = 'line' | 'column';

interface MessengersListProps {
  socials: SocialMessenger[];
  onClick?: () => void;
  variant: MessengersListVariant;
  zendeskButtonProps?: ZendeskButtonProps;
  closeButtonProps?: MessengerCloseButtonProps;
}

const MessengersList = (props: MessengersListProps) => {
  const { socials, onClick, variant, zendeskButtonProps, closeButtonProps } = props;

  const isLineVariant = variant === 'line';

  const sbsItemStyles: StylishBoxStyles = {
    mr: isLineVariant ? '16px' : '0',
    mb: isLineVariant ? '0' : '8px',
    hover: {
      opacity: '0.8',
    },
  };

  return (
    <StylishBox
      className='MessengersList'
      sbs={{
        display: 'flex',
        flexDirection: isLineVariant ? 'row' : 'column',
      }}
    >
      {zendeskButtonProps && (
        <StylishBox
          sbs={sbsItemStyles} // add or not space
        >
          <MessengerLauncherButton {...zendeskButtonProps} />
        </StylishBox>
      )}

      {socials.map(socialMessenger => (
        <StylishBox
          sbs={sbsItemStyles}
          key={`Social ${socialMessenger.name}`}
        >
          <StylishBox
            element='a'
            elementProps={{
              target: '_blank',
              rel: 'noreferrer',
              href: socialMessenger.link,
              title: socialMessenger.name,
              onClick,
            }}
            dataTestId={`${socialMessenger.name} Button`}
            dataMarker={`${socialMessenger.name} Button`}
          >
            <Image
              src={socialMessenger.img_url}
              alt={socialMessenger.name}
              width={40}
              height={40}
            />
          </StylishBox>
        </StylishBox>
      ))}



      {closeButtonProps && (
        <StylishBox
          dataTestId='close_button'
          dataMarker='Close Messengers'
        >
          <MessengerCloseButton {...closeButtonProps} />
        </StylishBox>
      )}
    </StylishBox>
  );
};

export default MessengersList;
