import { useEffect } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { COUNTRY, Country } from 'src/data/constants';
import { useTheme } from 'src/ui-kit/theme';
import { ZendeskWidgetProps } from './Messengers.types';
import { sendAnalyticEvent } from 'src/utils/analytics/sendAnalyticEvent';

type GroupType = 'Chat_UA';

const groupsMap: Record<Country, GroupType> = {
  ua: 'Chat_UA',
};

const ZendeskWidget = (props: ZendeskWidgetProps) => {
  const {
    zendeskKey,
    language,
    chatIsOpen,
    onCloseChat,
  } = props;

  const theme = useTheme();

  const groupType = groupsMap[COUNTRY];

  useEffect(() => {
    if (chatIsOpen) {
      ZendeskAPI('webWidget', 'show');
      ZendeskAPI('webWidget', 'open');
    }
  }, [chatIsOpen]);

  const handleLoadZendeskApi = () => {
    ZendeskAPI('webWidget', 'setLocale', language);
    // for hide the native launcher button
    ZendeskAPI('webWidget', 'hide');
    ZendeskAPI('webWidget:on', 'open', () => {
      sendAnalyticEvent({
        event: 'zendesk_support_click',
      });
    });
    ZendeskAPI('webWidget:on', 'close', () => {
      // for hide the native launcher button
      ZendeskAPI('webWidget', 'hide');
      onCloseChat();
    });
  };

  return (
    <Zendesk
      defer
      zendeskKey={zendeskKey}
      onLoaded={handleLoadZendeskApi}
      webWidget={{
        zIndex: theme.base.zIndex.zendeskWindow,
        chat: {
          departments: {
            select: groupType,
            enabled: [groupType],
          },
        },
      }}
      messenger={{
        zIndex: theme.base.zIndex.zendeskButton,
      }}
    />
  );
};

export default ZendeskWidget;
